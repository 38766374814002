exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-cable-drums-js": () => import("./../../../src/pages/products/cable-drums.js" /* webpackChunkName: "component---src-pages-products-cable-drums-js" */),
  "component---src-pages-products-construction-js": () => import("./../../../src/pages/products/construction.js" /* webpackChunkName: "component---src-pages-products-construction-js" */),
  "component---src-pages-products-furniture-js": () => import("./../../../src/pages/products/furniture.js" /* webpackChunkName: "component---src-pages-products-furniture-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-pallets-crates-js": () => import("./../../../src/pages/products/pallets-crates.js" /* webpackChunkName: "component---src-pages-products-pallets-crates-js" */)
}

